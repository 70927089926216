import {Spacer} from '@nfq/react-grid';
import {m as motion} from 'framer-motion';

import {Button} from 'Client/ui/components/action/buttons/Button';
import {P} from 'Client/ui/components/layout/Text';

import {useLogin} from 'Client/application/useCases/login/useLogin';
import {OpacityAnimation} from 'Client/ui/animations/shared';

/**
 * A login module component that provides a login button and displays an error message if login fails.
 * This component utilizes the `useLogin` hook to handle login functionality.
 *
 * @returns The rendered login module component.
 *
 * @example
 * ```tsx
 * const MyPage = () => {
 *   return (
 *     <div>
 *       <h1>Welcome to My Page</h1>
 *       <LoginModule />
 *     </div>
 *   );
 * };
 * ```
 */
const LoginModule = () => {
    const {error, handleLogin} = useLogin();

    return (
        <>
            <motion.div variants={OpacityAnimation}>
                <Button
                    label="Login via Brand Factory"
                    style="light"
                    onClick={handleLogin}
                />
            </motion.div>
            <Spacer y={1} isNotStretching />
            {error && (
                <motion.div variants={OpacityAnimation}>
                    <P $isLight>Something went wrong</P>
                </motion.div>
            )}
        </>
    );
};

LoginModule.displayName = 'LoginModule';

export {LoginModule};
import {Spacer, spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {H3, P, Topline} from 'Client/ui/components/layout/Text';

import {useModal} from 'Client/ui/hooks/useModal';

import {useSecret} from './useSecret';

/**
 * A React functional component that displays a modal with secret information.
 * The modal contains credits for the project management, design, concept, and programming.
 * The useSecret hook is used to control when the modal is opened.
 *
 * @returns A JSX element representing the modal.
 *
 * @example
 * ```tsx
 * <Secret />
 * ```
 */
const Secret = () => {
    const {Modal, openModal} = useModal();

    useSecret(openModal);

    return (
        <Modal size="small">
            <Wrapper>
                <Topline>Secret</Topline>
                <H3>.NFQ Credits</H3>
                <Spacer y={10} isNotStretching />
                <FixedTable>
                    <tbody>
                        <tr>
                            <FixedTd><P $align="right">Project Management:</P></FixedTd>
                            <FixedTd><P>Luca Stech</P></FixedTd>
                        </tr>
                        <tr>
                            <FixedTd><P $align="right">Design:</P></FixedTd>
                            <FixedTd><P>Claudia Meitzner</P></FixedTd>
                        </tr>
                        <tr>
                            <FixedTd><P $align="right">Konzept:</P></FixedTd>
                            <FixedTd><P>Claudia Meitzner</P></FixedTd>
                        </tr>
                        <tr>
                            <FixedTd />
                            <FixedTd><P>Luca Stech</P></FixedTd>
                        </tr>
                        <tr>
                            <FixedTd />
                            <FixedTd><P>Christoph Kruppe</P></FixedTd>
                        </tr>
                        <tr>
                            <FixedTd><P $align="right">Programming:</P></FixedTd>
                            <FixedTd><P>Christoph Kruppe</P></FixedTd>
                        </tr>
                        <tr>
                            <FixedTd />
                            <FixedTd><P>Aghyad Al Logie</P></FixedTd>
                        </tr>
                    </tbody>
                </FixedTable>
            </Wrapper>
        </Modal>
    );
};

Secret.displayName = 'Secret';

export {Secret};

const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: ${spacing(20)};
    width: 100%;
`;

const FixedTable = styled.table`
    table-layout: fixed;
    width: 100%;
`;

const FixedTd = styled.td`
    width: 50%;
`;
/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `LogoProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface LogoProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#FFF'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 40
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Logo'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 40
     */
    width?: number | string;
}

/**
 * The `Logo` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `LogoProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the LogoIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the LogoIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the LogoIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const LogoComponent = <Logo className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Logo = React.forwardRef<SVGSVGElement, LogoProps>((props, ref) => {
    const {
        className = '',
        color1 = '#FFF',
        height = 40,
        testId = 'Logo',
        width = 40
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 40 40"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g fill={color1}>
                <path clipRule="evenodd" d="M0 16.2c0 8.87 6.43 16.22 15.78 16.22 9.36 0 15.73-7.35 15.73-16.21C31.5 6.8 25.19 0 15.78 0 6.38 0 0 6.8 0 16.2Zm6.07 0c0-5.88 3.58-11.59 9.71-11.59 6.13 0 9.66 5.71 9.66 11.6 0 5.52-2.98 11.6-9.66 11.6-6.67 0-9.71-6.08-9.71-11.6Z" fillRule="evenodd" />
                <path d="M40 39.36V37.2h-5.6c2.24-2.2 5.17-4.87 5.17-7.78 0-2.69-1.7-4-4.3-4-1.4 0-2.83.35-4.07.98l.22 2.28c.9-.6 2-1.1 3.16-1.1 1.16 0 2.24.59 2.24 1.84 0 2.52-4.65 6.4-5.93 7.64v2.3H40Z" />
            </g>
        </svg>
    );
});

Logo.displayName = 'Logo';